.custom-back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #4ab8ff;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 5px 0px #004aad;
    outline: none;
}
