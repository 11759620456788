.friend-wait-game {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 95vh;
    padding: 1rem;
    box-sizing: border-box;
}

.friend-wait-game::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 20px 20px;
    background-color: #f4f4f4;
    background-image: 
        linear-gradient(to right, #9ab6d1 1px, transparent 1px),
        linear-gradient(to bottom, #9ab6d1 1px, transparent 1px);
    z-index: -1;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.friend-wait-text {
    background-color: #8ab1df;
    color: white;
    padding: 20px 40px;
    border-radius: 15px;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.error-message {
    background-color: #8ab1df;
    color: white;
    padding: 20px 40px;
    border-radius: 15px;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.content button {
    margin-top: 2rem;
}
