.bottom-ad-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    background-color: #333;
    color: white;
    text-align: center;
    font-size: 1rem;
}
