.language-switch {
    display: flex;
    gap: 10px;
}

.language-switch button {
    background-color: #4ab8ff;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 5px 0px #004aad;
    transition: all 0.2s ease;
    outline: none;
}

.language-switch button.active {
    background-color: #0b6cb6;
    box-shadow: 0px 5px 0px #002f6c;
}

.language-switch button:active {
    transform: translateY(3px);
    box-shadow: 0px 2px 0px #004aad;
}
