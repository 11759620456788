.game-container {
    height: 95vh;
    width: 100%;
    padding-top: 35px; 
    background-color: #f4f4f4;
}

.game-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    padding-left: 10px;
    padding-top: 7px;
    position: fixed;
    top: 0;
    z-index: 998;
}

.player-name, .opponent-name {
    margin-top: 0px;
    margin-bottom: 2px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.game-canvas-item {
    background-color: #f4f4f4;
}

.opponent-name {
    margin-left: 6px;
}

.player-info {
    text-align: left;
}

.opponent-info {
    text-align: right;
}

.player-score, .opponent-score {
    margin-top: 0px;
    color: #FFFFFF;
    font-weight: bold;
}

.player-info,
.opponent-info {
    display: flex;
    flex-direction: column;
    margin: 12px 0 0 0;
    min-width: 30%;
}

.opponent-info {
    margin-right: 20px;
}

.center-buttons {
    display: flex;
    flex-direction: column;
    min-width: 30%;
}

.leave-button {
    margin-top: 9px !important;
}

.game-canvas {
    padding-top: 110px; 
    width: 600px;
    height: 600px;
    background-color: #f4f4f4;
}

.blue-background {
    background-color: #8ab1df;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #9BB2D3;
    border-radius: 20px;
    padding: 17px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 250px;
    z-index: 1000;
}

.game-container.disable-interaction {
    pointer-events: none;
    opacity: 0.6;
}
.popup-content {
    text-align: center;
    font-family: Arial, sans-serif;
    color: #FFFFFF;
}

.popup-buttons-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.center-buttons .simple-red-button {
    margin: 4px 0;
    font-size: 13px;
    padding: 7px 14px;
}

.turn-text {
    margin: 7px 0 10px 0;
    align-self: center;
    font-size: 13px;
}

.close-button {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 25px;
    height: 25px;
    background-color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.close-button::before {
    content: "✕";
    color: #7C95BF;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
}

.close-button:hover {
    background-color: #6B84AA;
}

@keyframes pulse {
    0%, 100% {
      transform: scale(1);
      font-weight: normal;
    }
    50% {
      transform: scale(1.1);
      font-weight: bold;
    }
  }
  
  .your-turn-text {
    animation: pulse 0.5s ease 5;
  }