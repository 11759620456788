.settings-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}

.settings-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    background-size: 20px 20px; 
    background-image: 
        linear-gradient(to right, #9ab6d1 1px, transparent 1px), 
        linear-gradient(to bottom, #9ab6d1 1px, transparent 1px); 
    z-index: -1;
}

.settings-page .title {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
}

.settings-page .content-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

