
.rules-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
    padding: 65px 15px 15px 15px;
    box-sizing: border-box;
}

.rules-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 20px 20px;
    background-color: #f4f4f4;
    background-image: 
        linear-gradient(to right, #9ab6d1 1px, transparent 1px),
        linear-gradient(to bottom, #9ab6d1 1px, transparent 1px); 
    z-index: -1;
}

.rules-content {
    background-color: #8ab1df;
    border-radius: 15px;
    padding: 20px;
    max-width: 400px;
    width: 85%;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    position: relative;    
    height: 70vh;
    overflow-y: auto;
}

.rules-content h1 {
    font-size: 28px;
    margin: 0;
    color: white;
}

.rules-content h2 {
    color: white;
    margin: 0;
}

.rules-content p {
    color: white;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    text-align: start;
}

.rules-section-list p {
    margin-bottom: 5px;
}

.rules-section {
    margin-top: 15px;
    margin-bottom: 15px;
}

.rules-content .simple-button {
    background-color: #4ab8ff;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 5px 0px #004aad;
    transition: all 0.2s ease;
    outline: none;
}

.rules-content .simple-button:active {
    transform: translateY(3px);
    box-shadow: 0px 2px 0px #004aad;
}

.settings-button-container {
    position: absolute;
    top: 20px;
    right: 20px;
}
